import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../../../components/Breadcrumbs';
import axios from 'axios';
import { Table, Button } from 'antd';
import * as XLSX from 'sheetjs-style';


function AllemployeeAttend() {
  const [date1, setDate1] = useState('');
  const [date2, setDate2] = useState('');
  const [result, setResult] = useState([]);
  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [dayDifference, setDayDifference] = useState(0)

const exportToExcel = () => {
  const numberOfWorkingDays = dayDifference + 1;

  const formattedData = result.map(employee => {
    const attendanceData = {};
    employee.attendances.forEach(attendance => {
      const date = attendance.date;
      const dayOfWeek = new Date(date).toLocaleDateString('en-US', { weekday: 'short' });
      attendanceData[`${date.slice(5)}\n${dayOfWeek}`] = attendance.workingHours || '00:00';
    });

    return {
      Name: employee.full_name,
      Job: employee.jobcurrent,
      Nationality: employee.nationality,
      ...attendanceData,
      Total_Present: employee.totalPresent,
      Total_Absent: employee.totalAbsent,
      No_of_Working_Days: numberOfWorkingDays,
    };
  });

  const worksheet = XLSX.utils.json_to_sheet(formattedData);

  // Define column widths
  worksheet['!cols'] = [
    { wch: 20 },
    { wch: 15 },
    { wch: 15 },
    ...Array(Object.keys(formattedData[0]).length - 6).fill({ wch: 7 }),
    { wch: 15 },
    { wch: 15 },
    { wch: 20 },
  ];

  // Default header style with centered text and borders
  const headerStyle = {
    alignment: { horizontal: "center", vertical: "center", wrapText: true },
    font: { bold: true, color: { rgb: "000000" } },
    fill: { fgColor: { rgb: "C6E0B4" } },
    border: { top: { style: "thin" }, bottom: { style: "thin" }, left: { style: "thin" }, right: { style: "thin" } }
  };

  // Dark green header style for specific columns
  const darkGreenHeaderStyle = {
    ...headerStyle,
    fill: { fgColor: { rgb: "375623" } },  // Dark green background for specific headers
    font: { bold: true, color: { rgb: "FFFFFF" } }  // White font for better contrast
  };

  const headers = Object.keys(formattedData[0]);
  headers.forEach((header, index) => {
    const cellRef = XLSX.utils.encode_cell({ c: index, r: 0 });
    // Apply dark green header style only for specific headers
    if (['Total_Present', 'Total_Absent', 'No_of_Working_Days'].includes(header)) {
      worksheet[cellRef].s = darkGreenHeaderStyle;
    } else {
      worksheet[cellRef].s = headerStyle;
    }
  });

  // Define default cell style
  const defaultCellStyle = {
    alignment: { horizontal: "center", vertical: "center", wrapText: true },
    border: { top: { style: "thin" }, bottom: { style: "thin" }, left: { style: "thin" }, right: { style: "thin" } }
  };

  // Apply styles to all data cells
  result.forEach((employee, rowIndex) => {
    headers.forEach((header, colIndex) => {
      const cellRef = XLSX.utils.encode_cell({ c: colIndex, r: rowIndex + 1 });
      if (worksheet[cellRef]) {
        // Check if this header is a date with the day of the week
        const dayOfWeek = header.split("\n")[1];
        
        // Copy the default style
        const cellStyle = { ...defaultCellStyle };

        // If the day is Sunday, apply the green background color
        if (dayOfWeek === "Sun") {
          cellStyle.fill = { fgColor: { rgb: "92D050" } }; // Light green background for Sundays
        }
        
        worksheet[cellRef].s = cellStyle;
      }
    });
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Attendance");

  XLSX.writeFile(workbook, "Employee_Attendance.xlsx");
};

  

const search = async () => {
    try {
      const response = await axios.get(
        `https://hr.tanaghomtech.com/portal/public/api/allemployeeattendance?date_from=${date1}&date_to=${date2}`
      );
      const data = response.data.data;

      const updatedData = data.map((item) => {
        const totalPresent = item.attendances.filter(attendance => 
          attendance.Status === "Present" || attendance.Status === "Weekend" || attendance.Status === "Holiday"
        ).length;
        const totalAbsent = item.attendances.filter(attendance => attendance.Status === "Absent" || attendance.Status === "Leave").length;
        return { ...item, totalPresent, totalAbsent };
      });

      setResult(updatedData);
      generateDynamicColumns(updatedData);
  
    } catch (error) {
      console.error("Error fetching attendance data:", error);
    }
  };

const generateDynamicColumns = (data) => {
    const allDates = new Set();
    data.forEach(employee => {
      employee.attendances.forEach(attendance => {
        allDates.add(attendance.date);
      });
    });

    const dateColumns = Array.from(allDates).sort().map(date => {
      const formattedDate = new Date(date);
      const dayOfWeek = formattedDate.toLocaleDateString('en-US', { weekday: 'short' });
      const dateOnly = date.slice(5);
    
      return {
        title: (
          <div>
            {dateOnly} <br /> <span style={{ fontSize: '0.8em', color: '#888' }}>{dayOfWeek}</span>
          </div>
        ), 
        dataIndex: date,
        key: date,
        render: (text, record) => {
          const attendance = record.attendances.find(a => a.date === date);
          if (attendance) {
            if (attendance.CheckInTime && attendance.CheckOutTime) {
              const checkIn = new Date(`1970-01-01T${attendance.CheckInTime}Z`);
              const checkOut = new Date(`1970-01-01T${attendance.CheckOutTime}Z`);
              return `${attendance.workingHours}`;
            } else {
              return '00:00';
            }
          } else {
            return '00:00';
          }
        }
      };
    });
    
    setDynamicColumns(dateColumns);
  };

  // Export to Excel function


const calculateDayDifference = () => {
    if (date1 && date2) {
      const startDate = new Date(date1);
      const endDate = new Date(date2);
      const differenceInTime = endDate - startDate;
      const differenceInDays = differenceInTime / (1000 * 3600 * 24);
      setDayDifference(differenceInDays);
    } else {
      setDayDifference(0);
    }
  };

  useEffect(() => {
    calculateDayDifference();
  }, [date1, date2]);

const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Full Name",
      dataIndex: "full_name",
      key: "full_name",
    },
    {
      title: "Job current",
      dataIndex: "jobcurrent",
      key: "jobcurrent",
    },
    {
      title: "Nationality",
      dataIndex: "nationality",
      key: "nationality",
    },
    ...dynamicColumns,
    {
      title: "Total Present",
      dataIndex: "totalPresent",
      key: "totalPresent",
      render: (text) => `${text} days`
    },
    {
      title: "Total Absent",
      dataIndex: "totalAbsent",
      key: "totalAbsent",
      render: (text) => `${text} days`
    },
    {
      title: "No. of Working Days",
      render: () => `${dayDifference + 1} days`
    }
  ];

  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="All Employee Attendance"
            title="Dashboard"
            subtitle="Attendance"
            modal="#add_employee"
            name="Add Employee"
          />
          <div className="row filter-row">
            <div className="col-sm-6 col-md-3">
              <label>From:</label>
              <input
                type='date'
                value={date1}
                onChange={(e) => setDate1(e.target.value)}
                className="form-control"
              />
            </div>
            <div className="col-sm-6 col-md-3">
              <label>To:</label>
              <input
                type='date'
                value={date2}
                onChange={(e) => setDate2(e.target.value)}
                className="form-control"
              />
            </div>
            <div className="col-sm-6 col-md-3">
              <button onClick={search} style={{ marginTop: "20px" }} className="btn btn-success btn-block w-100">
                Search
              </button>
            </div>
            <div className="col-sm-6 col-md-3">
              <Button onClick={exportToExcel} style={{ marginTop: "20px" }} className="btn btn-primary w-100">
                Export to Excel
              </Button>
            </div>
          </div>
          <div>
            <Table
              className="table-striped"
              style={{ overflowX: "auto", marginTop: "20px" }}
              columns={columns}
              dataSource={result}
              rowKey={(record) => record.id}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllemployeeAttend;
